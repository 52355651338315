import React from "react"
import Marquee from "react-marquee-slider"

const agencies = [
  {
    name: "Horizon Media",
    logo: require("../images/agency-logos/horizon.svg"),
  },
  { name: "PMG", logo: require("../images/agency-logos/pmg.svg") },
  // {
  //   name: "Connelly Partners",
  //   logo: require("../images/agency-logos/connelly-partners.svg"),
  // },
  { name: "Havas", logo: require("../images/agency-logos/havas.svg") },
  { name: "Zenith", logo: require("../images/agency-logos/zenith.svg") },
  { name: "Mindshare", logo: require("../images/agency-logos/mindshare.svg") },
  // {
  //   name: "Bader Rutter",
  //   logo: require("../images/agency-logos/bader-rutter.svg"),
  // },
  // {
  //   name: "Crossmedia",
  //   logo: require("../images/agency-logos/crossmedia.svg"),
  // },
  { name: "Rise", logo: require("../images/agency-logos/rise.svg") },
  { name: "UM", logo: require("../images/agency-logos/um.svg") },
  { name: "Mekanism", logo: require("../images/agency-logos/mekanism.svg") },
  {
    name: "Merkley + Partners",
    logo: require("../images/agency-logos/mp.svg"),
  },
  // { name: "Greater Than One", logo: require("../images/agency-logos/gto.svg") },
  {
    name: "Worldwide Agency",
    logo: require("../images/agency-logos/wagency.svg"),
  },
]
const AgenciesMarquee = () => (
  <div className="py-5 agencies-marquee">
    <h2 className="has-text-centered is-size-5 px-4">
      Join Leading Agencies Activating inPowered AI
    </h2>

    <div className="pt-6">
      <Marquee velocity={16} style={{ display: "flex" }}>
        {agencies.map(agency => (
          <div className="px-6" key={agency.name}>
            <img src={agency.logo} alt={agency.name} style={{ height: 50 }} />
          </div>
        ))}
      </Marquee>
    </div>
  </div>
)

export default AgenciesMarquee
